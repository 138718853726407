import Storage from "./storage";
import config from "./config";

// export const userInfo = Storage.get('user');
// console.log(userInfo.user.role)


export const Roles = {
    SuperAdmin: "SuperAdmin",
    User: "User"
   }


   
