import React from 'react'

const Scroller = () => {
  return (
    <div className="bg-black" >
    <div class="cryptohopper-web-widget rounded-0" data-id="2" data-theme="dark" data-realtime="on" data-logos="0"></div>
  </div>
  )
}

export default Scroller